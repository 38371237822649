.projects {
    background-color: aqua;
  }
   
  .projects__main__container h1, 
  .projects__main__container p {
    text-align: center;
    margin-top: 3rem;
  }
   
  #projects__card__container {
    width: 50%;
    padding: 10px;
    border: none;
  }
   
  .projects__image:hover {
    cursor: pointer;
    filter: brightness(50%);
  }
   
  .projects__click__info {
    position: absolute;
    top: 50%;  
    left: 50%; 
    transform: translate(-50%, -50%);
    color: var(--font-color);
    display: none;
  }
   
  .projects__image:hover + .projects__click__info {
    display: block;
  }
   
  .modal-backdrop.show {
    opacity: .1 !important;
  }
   
  #projects__modal__link {
    padding: 0 0 1rem 1rem
  }
   
  #projects__modal__link:link {
    text-decoration: none;
  }
   
  @media only screen and (max-width: 600px){
    #projects__card__container {
      width: 100%;
      padding: 0.5rem 2.5rem;
      border: none;
    }
  }