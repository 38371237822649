@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200&display=swap');
.App {
  font-family: 'Nunito', sans-serif;
}

:root {
  --nav-color: hsl(210, 11%, 15%);
  --nav-color-hover: hsl(210, 11%, 45%);
  --nav-color-color: hsl(210, 11%, 45%);
  --light-color: hsl(209, 100%, 92%);
  --font-color: hsl(0, 0%, 100%);
  --font-color-hover: hsl(0, 0%, 40%);
}